<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="
              `/tests/${$route.params.testType}/edit/test?testTypeId=${$route.params.testTypeId}`
            "
          >
            {{ testName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="
              `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${this.$route.params.sectionId}`
            "
          >
            {{ word.toUpperFirstLetter(sectionName) }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1 slot="title">
      {{ pageTitle }}
    </h1>
    <PassageForm
      :passage="passage"
      @setPassage="setPassage"
      :testType="$route.params.testType"
      :sectionName="sectionName"
    />
    <div style="text-align: center; margin-bottom: 20px">
      <el-button style="margin: 0px 4px" type="primary" @click="createPassage">
        {{ $t("button.save") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { word } from "@ivy-way/material";
import testsApi from "@/apis/tests.js";
import Breadcrumb from "@/components/Breadcrumb";
import PassageForm from "@/components/tests/PassageForm";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    PassageForm
  },
  computed: {
    word() {
      return word;
    },
    isEditMode() {
      return this.$route.name === "EditPassages";
    },
    pageTitle() {
      if (this.isEditMode) {
        return `Edit Passage ${this.passage.title || ""}`;
      } else {
        return "Add Passage";
      }
    }
  },
  data() {
    return {
      sectionName: "",
      testName: "",
      passage: {
        title: "",
        intro: "",
        content: "",
        below_content: ""
      }
    };
  },
  async created() {
    if (this.isEditMode) {
      const passage = await testsApi.getPassage(
        this.$route.params.testType,
        this.$route.params.passageId
      );
      this.passage = { ...passage };
    }
    const { full_name, subjects } = await testsApi.getTest(
      this.$route.params.testType,
      this.$route.params.testTypeId
    );
    this.testName = full_name;
    this.sectionName = subjects.find(
      subject => String(subject.id) === this.$route.params.sectionId
    ).name;
  },
  methods: {
    setPassage(passage) {
      this.passage = { ...this.passage, ...passage };
    },
    async createPassage() {
      const removeSuffixedSpace = (content) => {
        while(content.includes("&nbsp; </p>") || content.includes("&nbsp;</p>")) {
          content = content.replace("&nbsp; </p>", "</p>").replace("&nbsp;</p>", "</p>");
        }
        return content;
      };
      const postPassage = {
        ...this.passage,
        content: removeSuffixedSpace(this.passage.content || ""),
        below_content: removeSuffixedSpace(this.passage.below_content || "")
      };
      try {
        if (this.isEditMode) {
          await testsApi.updateSubjectPassage(
            this.$route.params.testType,
            this.$route.params.passageId,
            postPassage
          );
        } else {
          await testsApi.createSubjectPassage(
            this.$route.params.testType,
            this.$route.params.sectionId,
            postPassage
          );
        }
        this.$router.push({
          path: `/tests/${this.$route.params.testType}/test/${this.$route.params.testTypeId}/section/${this.$route.params.sectionId}`
        });
        if (this.isEditMode) {
          this.$message.success(this.$t("message.update_success"));
        } else {
          this.$message.success(this.$t("message.create_success"));
        }
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style>
kbd {
  background-color: #000;
  border-color: #000;
  color: #fff;
  padding: 0 10px;
}
</style>
<style scoped>
.container >>> .el-dialog__body {
  padding: 10px 20px;
}
</style>
