<template>
  <div>
    <el-dialog
      title="Passage 內容"
      :visible.sync="visiblePreviewPassageDialog"
      width="600px"
      :before-close="
        () => {
          this.visiblePreviewPassageDialog = false;
        }
      "
    >
      <h2 style="padding-left:24px;text-align:center">
        {{ previewPassage.title }}
        <router-link
          :to="
            `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/passage/${previewPassage.id}/edit`
          "
        >
          <i class="fas fa-edit action-icon" />
        </router-link>
      </h2>
      <h3 v-if="previewPassage.below_content">Passage I</h3>
      <div
        style="line-height: 35px;"
        class="passage"
        v-html="previewPassage.content"
      />
      <div v-if="previewPassage.below_content">
        <h3>Passage II</h3>
        <div
          style="line-height: 35px;"
          class="passage"
          v-html="previewPassage.below_content"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              this.visiblePreviewPassageDialog = false;
            }
          "
        >
          回上一步
        </el-button>
        <el-button
          type="primary"
          @click="() => copyThisPassageToForm(previewPassage)"
        >
          複製此 Passage 資料至表單
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查詢現有 Passage"
      :visible.sync="visibleSearchPassageDialog"
      width="600px"
      :before-close="
        () => {
          toggleSearchPassage(false);
        }
      "
    >
      <el-row class="rowGap">
        <el-col :span="21">
          <el-input
            v-model="searchVal"
            placeholder="Title/Intro/Passage I/Passage II"
          />
        </el-col>
        <el-col :span="2">
          <el-button @click="searchPassages" type="primary">
            {{ $t("button.search") }}
          </el-button>
        </el-col>
      </el-row>
      <el-table :data="searchedPassages" style="width: 100%;">
        <el-table-column label="Content" width="200px">
          <template slot-scope="scope">
            {{
              `${scope.row.content.slice(0, 20)}${
                scope.row.content.length > 20 ? "..." : ""
              }`
            }}
          </template>
        </el-table-column>
        <el-table-column label="Action">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="
                () => {
                  openPreviewPassage(scope.row);
                }
              "
              type="primary"
            >
              查看內容
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="() => copyThisPassageToForm(scope.row)"
            >
              複製此 Passage 資料至表單
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              toggleSearchPassage(false);
            }
          "
        >
          {{ $t("button.cancel") }}
        </el-button>
      </span>
    </el-dialog>
    <hr class="separate-line" />
    <el-button
      type="primary"
      @click="
        () => {
          toggleSearchPassage(true);
        }
      "
    >
      查詢現有 Passage 帶入
    </el-button>
    <div class="row line">
      <div class="col-sm-12">
        <h5>Title:</h5>
        <el-input
          placeholder="请输入标题"
          :value="passage.title"
          @input="title => $emit('setPassage', { title })"
        >
        </el-input>
      </div>
    </div>
    <div class="row line">
      <div class="col-sm-12">
        <h5>Intro:</h5>
        <Ckeditor
          height="100"
          :value="passage.intro"
          @input="intro => $emit('setPassage', { intro })"
        />
      </div>
    </div>
    <div class="row line">
      <div class="col-sm-12">
        <el-alert
          v-if="sectionName === 'writing' || sectionName === 'english'"
          type="success"
          :closable="false"
          class="explanation"
        >
          <h6>操作說明：</h6>
          <ul class="explanationText">
            <li>
              如要輸入題號（如 [11]），請選擇題號，並在在工具列的 Styles
              樣式選擇 “Question Number”，數字就會反白。
            </li>
            <li>
              如要字體要畫底線或顯示斜體，請記得選擇對應底線樣式（可用選擇文字後按
              ctrl-u/cmd-u 畫底線，ctrl-i/cmd-i 顯示斜體）
            </li>
          </ul>
          <h6>操作教學影片：</h6>
          <ul>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=-HIa7HsZ3zw&list=PLM3ojFqawjfCdvS6arUsZC_Hpffku-QNW&index=27"
            >
              <li>
                如何在考卷文章中增加題號。
              </li>
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=p97DiEIxsC0&list=PLM3ojFqawjfCdvS6arUsZC_Hpffku-QNW&index=27"
            >
              <li>
                如何上傳圖片到考卷文章。
              </li>
            </a>
          </ul>
        </el-alert>
      </div>
    </div>
    <div class="row line">
      <div style="margin-bottom:20px" class="col-sm-12">
        <h5>Passage I</h5>
        <Ckeditor
          :key="sectionName"
          height="300"
          :enableQuestionNumStyle="sectionIsSatWriting || sectionIsActEnglish"
          :testType="testType"
          :value="passage.content"
          @input="content => $emit('setPassage', { content })"
        />
      </div>
      <div style="margin-bottom:20px" class="col-sm-12" v-if="showPassage2">
        <h5>Passage II</h5>
        <Ckeditor
          :key="sectionName"
          height="300"
          :enableQuestionNumStyle="sectionIsSatWriting || sectionIsActEnglish"
          :testType="testType"
          :value="passage.below_content"
          @input="below_content => $emit('setPassage', { below_content })"
        />
      </div>
      <div class="col-sm-12" v-else>
        <el-button
          v-if="!sectionIsSatWriting"
          type="success"
          @click="showPassage2 = true"
        >
          Add Passage II
        </el-button>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import Ckeditor from "@/components/ckeditor/index.vue";
import Ckeditor5 from "@/components/ckeditor/ckeditor5.vue";
import testsApi from "@/apis/tests.js";

export default {
  components: {
    Ckeditor
    // Ckeditor5
  },
  props: ["passage", "testType", "sectionName"],
  computed: {
    sectionIsSatWriting() {
      return this.testType === "sat" && this.sectionName === "writing";
    },
    sectionIsActEnglish() {
      return this.testType === "act" && this.sectionName === "english";
    }
  },
  data() {
    return {
      searchVal: "",
      visibleSearchPassageDialog: false,
      visiblePreviewPassageDialog: false,
      searchedPassages: [],
      previewPassage: {},
      showPassage2: false
    };
  },
  created() {
    if (this.passage.below_content) {
      this.showPassage2 = true;
    }
  },
  methods: {
    toggleSearchPassage(status) {
      this.visibleSearchPassageDialog = status;
    },
    async searchPassages() {
      this.searchedPassages = await testsApi.getSubjectPassages(
        this.$route.params.testType,
        this.$route.params.sectionId,
        this.searchVal
      );
    },
    copyThisPassageToForm(passage) {
      this.$emit("setPassage", {
        title: passage.title,
        intro: passage.intro,
        content: passage.content,
        below_content: passage.below_content
      });
      this.toggleSearchPassage(false);
      this.visiblePreviewPassageDialog = false;
    },
    openPreviewPassage(passage) {
      this.previewPassage = passage;
      this.visiblePreviewPassageDialog = true;
    }
  }
};
</script>

<style scope>
kbd {
  background-color: #000;
  border-color: #000;
  color: #fff;
  padding: 0 10px;
}
</style>
<style scoped>
.line {
  margin-top: 10px;
  margin-bottom: 15px;
}
::v-deep .ck.ck-content {
  max-height: 400px;
}
.explanation {
  margin: 20px 0px;
}

.explanationText > li {
  color: #000;
}

::v-deep .el-alert__description {
  font-size: 14px;
}
</style>
